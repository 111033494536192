progress {
  display: flex;
  height: 10px;
  align-self: right;
  -webkit-appearance: none;
  appearance: none;
}
progress::-webkit-progress-bar {
  background-color: hsl(224, 78%, 86%);
  border-radius: 6px;
}
progress::-webkit-progress-value {
  background-color: #7b8794;
  border-radius: 6px;
}
progress::-moz-progress-bar {
  background-color: #7b8794;
}
